import { Interface } from '@ethersproject/abi'
import { getContract } from 'utils'
import { provider as ProviderType } from 'web3-core'
import ERC20_ABI from './erc20.json'
import ERC20_BYTES32_ABI from './erc20_bytes32.json'

const ERC20_INTERFACE = new Interface(ERC20_ABI)

const ERC20_BYTES32_INTERFACE = new Interface(ERC20_BYTES32_ABI)

export default ERC20_INTERFACE
export { ERC20_ABI, ERC20_BYTES32_INTERFACE, ERC20_BYTES32_ABI }

export const getTokenBalance = async (
  provider: ProviderType,
  tokenAddress: string,
  userAddress: string,
): Promise<string> => {
  const contract = getContract(tokenAddress, ERC20_ABI, provider)
  try {
    const balance: string = await contract.balanceOf(userAddress)
    return balance.toString()
  } catch (e) {
    return '0'
  }
}
