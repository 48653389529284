import { useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProvider } from 'utils/providers'
import useRefresh from 'hooks/useRefresh'
import { useFarmFromPid } from 'state/farms/hooks'
import { getLicoPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import {
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchLicoVaultPublicData,
  fetchLicoVaultUserData,
  fetchLicoVaultFees,
  fetchPoolsStakingLimitsAsync,
} from '.'
import { State, Pool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(blockNumber))
    }

    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync())
  }, [dispatch, slowRefresh])
}

export const usePools = (account): { pools: Pool[]; userDataLoaded: boolean } => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

export const useLicoPoolApr = () => {
  const [licoPoolApr, setLicoPoolApr] = useState(0)
  const { pools } = usePools(null)
  const licoFarm = useFarmFromPid(0)
  const licoPool = pools[0]

  useEffect(() => {
    if (licoPool && licoFarm?.poolWeight)
      setLicoPoolApr(
        getLicoPoolApr(
          licoPool.stakingTokenPrice,
          licoPool.earningTokenPrice,
          getBalanceNumber(new BigNumber(licoPool.totalStaked), licoPool.stakingToken.decimals),
          parseFloat(licoFarm.poolWeight),
        ),
      )
  }, [licoFarm, licoPool])

  return licoPoolApr
}

export const useFetchLicoVault = () => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchLicoVaultPublicData())
  }, [dispatch, fastRefresh])

  useEffect(() => {
    dispatch(fetchLicoVaultUserData({ account }))
  }, [dispatch, fastRefresh, account])

  useEffect(() => {
    dispatch(fetchLicoVaultFees())
  }, [dispatch])
}

export const useLicoVault = () => {
  const {
    totalShares: totalSharesAsString,
    pricePerFullShare: pricePerFullShareAsString,
    totalLicoInVault: totalLicoInVaultAsString,
    estimatedLicoBountyReward: estimatedLicoBountyRewardAsString,
    totalPendingLicoHarvest: totalPendingLicoHarvestAsString,
    fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
    userData: {
      isLoading,
      userShares: userSharesAsString,
      licoAtLastUserAction: licoAtLastUserActionAsString,
      lastDepositedTime,
      lastUserActionTime,
    },
  } = useSelector((state: State) => state.pools.licoVault)

  const estimatedLicoBountyReward = useMemo(() => {
    return new BigNumber(estimatedLicoBountyRewardAsString)
  }, [estimatedLicoBountyRewardAsString])

  const totalPendingLicoHarvest = useMemo(() => {
    return new BigNumber(totalPendingLicoHarvestAsString)
  }, [totalPendingLicoHarvestAsString])

  const totalShares = useMemo(() => {
    return new BigNumber(totalSharesAsString)
  }, [totalSharesAsString])

  const pricePerFullShare = useMemo(() => {
    return new BigNumber(pricePerFullShareAsString)
  }, [pricePerFullShareAsString])

  const totalLicoInVault = useMemo(() => {
    return new BigNumber(totalLicoInVaultAsString)
  }, [totalLicoInVaultAsString])

  const userShares = useMemo(() => {
    return new BigNumber(userSharesAsString)
  }, [userSharesAsString])

  const licoAtLastUserAction = useMemo(() => {
    return new BigNumber(licoAtLastUserActionAsString)
  }, [licoAtLastUserActionAsString])

  return {
    totalShares,
    pricePerFullShare,
    totalLicoInVault,
    estimatedLicoBountyReward,
    totalPendingLicoHarvest,
    fees: {
      performanceFee,
      callFee,
      withdrawalFee,
      withdrawalFeePeriod,
    },
    userData: {
      isLoading,
      userShares,
      licoAtLastUserAction,
      lastDepositedTime,
      lastUserActionTime,
    },
  }
}
