import React from 'react'
import styled from 'styled-components'
import { Link, Text } from '@liquidcollectibles/uikit'
import { useTranslation } from 'contexts/Localization'
import TwitterSvg from './TwitterSvg'
import TelegramSvg from './TelegramSvg'
import DiscordSvg from './DiscordSvg'
import MediumSvg from './MediumSvg'
import ApeSwapSvg from './ApeSwapSvg'
import LogoSvg from './LogoSvg'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 32px 16px;
  background: #121212;
  width: 100%;
  padding: 36px 64px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 36px 128px;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 36px 142px;
  }
`

const UsefulWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 70%;
  }
`

const SocialAndLogoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    justify-content: space-evenly;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    justify-content: space-between;
  }
`

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0;
  }
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 150px;
  padding-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    align-items: flex-start;
    justify-content: space-evenly;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;

  ${({ theme }) => theme.mediaQueries.xl} {
    align-items: flex-end;
    justify-content: center;
    width: 20%;
  }
`

const MonsterWrapper = styled.div`
  display: none;
  width: 30%;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
`

const imagePath = '/images/home/'
const imageSrc = 'animatedlimos'

const LicoFooter = () => {
  const { t } = useTranslation()

  return (
    <>
      <Wrapper>
        <UsefulWrapper>
          <SocialAndLogoWrapper>
            <LogoSvg />
            <SocialsWrapper>
              <Link external mr="12px" href="https://twitter.com/LiqCollectibles">
                <TwitterSvg />
              </Link>
              <Link external mr="12px" href="https://t.me/LiquidCollectibles">
                <TelegramSvg />
              </Link>
              <Link external mr="12px" href="https://discord.gg/tR8hMsDhQh">
                <DiscordSvg />
              </Link>
              <Link external href="http://liquidcollectibles.medium.com/">
                <MediumSvg />
              </Link>
            </SocialsWrapper>
          </SocialAndLogoWrapper>
          <DataWrapper>
            <DataColumn>
              <Text fontSize="14px" mb="12px" color="primary">
                {t('INFO')}
              </Text>
              <Link small color="text" external href="https://docs.liquidcollectibles.io/tokenomics/the-lico-token">
                {t('Token')}
              </Link>
              <Link small color="text" external href=" https://docs.liquidcollectibles.io/info/privacy-policy">
                {t('Privacy Policy')}
              </Link>
              <Link small color="text" external href="https://docs.liquidcollectibles.io/info/terms-of-use">
                {t('Terms of Service')}
              </Link>
            </DataColumn>
            <DataColumn>
              <Text fontSize="14px" mb="12px" color="primary">
                {t('RESOURCES')}
              </Text>
              {/* <Link small color="text" external href="https://github.com/LiquidCollectibles">
                Github
              </Link> */}
              <Link small color="text" external href="https://docs.liquidcollectibles.io/">
                {t('Docs')}
              </Link>
              <Link small color="text" external href="https://liquid-collectibles.nolt.io">
                {t('Community Feedback')}
              </Link>
              <Link small color="text" external href="https://liquid-collectibles.nolt.io/roadmap">
                {t('Roadmap')}
              </Link>
            </DataColumn>
            <ButtonWrapper>
              <Link external href="https://apeswap.finance">
                <ApeSwapSvg />
              </Link>
            </ButtonWrapper>
          </DataWrapper>
        </UsefulWrapper>
        <MonsterWrapper>
          <img src={`${imagePath}${imageSrc}.png`} alt="Shadow Monster" style={{ maxWidth: '150px' }} />
        </MonsterWrapper>
      </Wrapper>
    </>
  )
}

export default LicoFooter
