import React from 'react'
import { Svg, SvgProps } from '@liquidcollectibles/uikit'

const FooterLogoSvg: React.FC<SvgProps> = () => {
  return (
    <Svg width="87" height="132" viewBox="0 0 29 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9446 2.79331L26.6949 21.6866L13.5979 4.52359C13.5074 4.40401 13.4041 4.2947 13.2898 4.19759L13.6731 0.973484C13.7047 0.705923 13.8334 0.459267 14.0348 0.280315C14.2362 0.101363 14.4963 0.00256606 14.7657 0.00266967C14.8098 -0.000889889 14.8542 -0.000889889 14.8983 0.00266967L27.981 1.55741C28.1257 1.57467 28.2655 1.62043 28.3924 1.69202C28.5193 1.76362 28.6308 1.85964 28.7203 1.97455C28.8099 2.08946 28.8759 2.22097 28.9144 2.3615C28.9529 2.50203 28.9632 2.64879 28.9446 2.79331Z"
        fill="white"
        fillOpacity="0.87"
      />
      <path
        d="M25.9176 24.8498L24.3521 26.0463L23.7395 26.512L23.1234 26.9813L22.6612 27.3396L22.0917 27.773L22.0236 27.8232C21.7179 28.0477 21.4277 28.2698 21.1531 28.4895L21.0886 28.5397C18.9213 30.2341 17.5385 31.6276 16.7002 32.7883C16.3618 33.2418 16.0802 33.735 15.8619 34.2571C14.8768 36.7074 16.711 37.8394 17.3271 38.7386C18.3374 40.236 18.083 42.7401 16.2023 43.1162C14.0529 43.5497 12.6199 41.4074 14.1424 37.9469C14.6175 36.8426 14.671 35.6024 14.2929 34.4613C14.2041 34.1615 14.0977 33.8672 13.974 33.58C13.8988 33.4045 13.8164 33.2218 13.7305 33.0498C13.6445 32.8779 13.5585 32.7202 13.4654 32.5555C13.2504 32.165 13.0104 31.7745 12.7489 31.3876C12.6486 31.2336 12.5447 31.0831 12.4408 30.9291C11.8641 30.0836 11.2407 29.2418 10.6496 28.4214V28.3964L0.225013 14.7405C0.0482752 14.508 -0.0290843 14.215 0.00986227 13.9255C0.0488088 13.6361 0.200891 13.374 0.432795 13.1965L10.904 5.20427C11.0975 5.05653 11.334 4.97603 11.5775 4.975C11.758 4.97377 11.9357 5.01944 12.0933 5.10755C12.2334 5.18033 12.3557 5.28307 12.4516 5.40846L12.5805 5.57683L13.0462 6.18941L26.1182 23.3166C26.2915 23.5478 26.3675 23.8376 26.33 24.1241C26.2925 24.4105 26.1445 24.671 25.9176 24.8498Z"
        fill="white"
        fillOpacity="0.87"
      />
      <path
        d="M21.146 28.4859C21.4206 28.2686 21.7108 28.0465 22.0165 27.8196L21.146 28.4859Z"
        fill="white"
        fillOpacity="0.87"
      />
    </Svg>
  )
}

export default FooterLogoSvg
