import React from 'react'
import { Svg, SvgProps } from '@liquidcollectibles/uikit'

const Logo: React.FC<SvgProps> = (props) => (
  <Svg width="149" height="65" viewBox="0 0 149 65" {...props}>
    <path d="M64.7245 32.501H51.7637V14.3218H55.4911V29.1516H64.7138L64.7245 32.501Z" fill="white" />
    <path d="M72.8231 32.5005H69.0957V14.3213H72.8231V32.5005Z" fill="white" />
    <path
      d="M86.8505 32.8093C85.6423 32.7906 84.4503 32.5282 83.3459 32.0378C82.2415 31.5473 81.2475 30.839 80.4234 29.9552C78.7509 28.193 77.8452 25.8397 77.9047 23.4109C77.8713 22.1713 78.09 20.938 78.5477 19.7855C79.0054 18.6331 79.6924 17.5857 80.5671 16.7068C81.4433 15.8015 82.4987 15.089 83.6659 14.615C84.8332 14.141 86.0865 13.9158 87.3458 13.9538C88.5966 13.9199 89.8407 14.1473 90.9987 14.6213C92.1567 15.0953 93.2031 15.8056 94.0711 16.7068C94.95 17.5847 95.6426 18.631 96.1074 19.7829C96.5723 20.9349 96.7997 22.1689 96.7762 23.4109C96.7923 25.2925 96.2493 27.1366 95.216 28.7092C94.2345 30.2531 92.7874 31.4447 91.0838 32.1118C91.4076 32.5387 91.8289 32.8818 92.3125 33.1125C92.7961 33.3432 93.3279 33.4547 93.8634 33.4377C94.6161 33.4481 95.3587 33.2647 96.02 32.9052V36.0469C95.1559 36.4412 94.2169 36.6446 93.2671 36.6433C91.2045 36.6326 89.0657 35.3546 86.8505 32.8093ZM83.2935 27.6708C83.8102 28.224 84.4388 28.6607 85.1376 28.9518C85.8364 29.2429 86.5891 29.3816 87.3458 29.3588C88.0935 29.374 88.8361 29.2316 89.5251 28.9408C90.2141 28.6499 90.8341 28.2172 91.3448 27.6708C91.8785 27.101 92.2944 26.4313 92.5685 25.7002C92.8427 24.9692 92.9697 24.1912 92.9422 23.4109C92.9703 22.6305 92.8436 21.8523 92.5694 21.1212C92.2952 20.3901 91.879 19.7205 91.3448 19.151C90.8341 18.6045 90.2141 18.1718 89.5251 17.881C88.8361 17.5901 88.0935 17.4477 87.3458 17.463C86.5936 17.4439 85.8459 17.5845 85.152 17.8755C84.4581 18.1664 83.8338 18.6011 83.3201 19.151C82.7866 19.721 82.3709 20.3907 82.0968 21.1217C81.8226 21.8527 81.6955 22.6306 81.7227 23.4109C81.6919 24.1893 81.8149 24.9662 82.0844 25.6971C82.3539 26.428 82.7648 27.0987 83.2935 27.6708Z"
      fill="white"
    />
    <path
      d="M109.215 32.8624C108.189 32.8962 107.167 32.7252 106.208 32.3595C105.249 31.9938 104.372 31.4407 103.629 30.7324C102.178 29.3337 101.45 27.3689 101.446 24.8377V14.2944H105.174V24.9442C105.135 25.5299 105.212 26.1175 105.402 26.6731C105.591 27.2287 105.888 27.7415 106.276 28.1818C106.663 28.563 107.125 28.86 107.633 29.0543C108.141 29.2486 108.683 29.3358 109.226 29.3107C109.772 29.3375 110.318 29.2511 110.83 29.0568C111.341 28.8626 111.807 28.5646 112.197 28.1818C112.585 27.7415 112.883 27.2287 113.072 26.6731C113.261 26.1175 113.338 25.5299 113.3 24.9442V14.2944H117.027V24.8377C117.027 27.3689 116.29 29.3337 114.817 30.7324C114.072 31.4416 113.192 31.9953 112.231 32.361C111.269 32.7267 110.244 32.8971 109.215 32.8624Z"
      fill="white"
    />
    <path d="M126.172 32.5005H122.444V14.3213H126.172V32.5005Z" fill="white" />
    <path
      d="M138.665 32.5005H131.859V14.3213H138.665C141.611 14.3213 144.009 15.1697 145.859 16.8666C146.769 17.6907 147.489 18.7028 147.969 19.8328C148.449 20.9629 148.677 22.1838 148.638 23.4109C148.677 24.642 148.449 25.867 147.969 27.0013C147.489 28.1357 146.769 29.1526 145.859 29.9818C144.023 31.661 141.625 32.5005 138.665 32.5005ZM135.597 17.6707V29.1512H138.35C140.379 29.1512 141.955 28.6187 143.09 27.6282C143.665 27.0931 144.116 26.438 144.411 25.7092C144.705 24.9804 144.836 24.1958 144.794 23.4109C144.836 22.6252 144.705 21.8398 144.411 21.1101C144.117 20.3805 143.665 19.7244 143.09 19.1883C141.955 18.1765 140.379 17.6707 138.35 17.6707H135.597Z"
      fill="white"
    />
    <path
      d="M57.187 48.4766C56.4103 48.4986 55.6369 48.3659 54.912 48.0862C54.187 47.8065 53.525 47.3853 52.9644 46.8472C52.4112 46.3052 51.9765 45.6544 51.6877 44.9358C51.399 44.2172 51.2626 43.4465 51.287 42.6724C51.2623 41.9015 51.3987 41.1339 51.6875 40.4187C51.9763 39.7036 52.4112 39.0565 52.9644 38.519C53.5197 37.9721 54.1803 37.5437 54.9062 37.26C55.6321 36.9762 56.4081 36.8429 57.187 36.8683C58.2475 36.8501 59.2942 37.1109 60.2222 37.6244V39.3976C59.3242 38.814 58.274 38.5084 57.203 38.519C56.6575 38.5044 56.1147 38.6017 55.6082 38.8051C55.1018 39.0084 54.6424 39.3133 54.2583 39.7011C53.8717 40.0907 53.5687 40.5553 53.3682 41.0663C53.1676 41.5772 53.0737 42.1238 53.0922 42.6724C53.0744 43.2255 53.1702 43.7764 53.3735 44.2911C53.5768 44.8058 53.8834 45.2734 54.2743 45.665C54.6685 46.0516 55.1368 46.3542 55.6512 46.5546C56.1655 46.755 56.7152 46.8491 57.2669 46.8312C58.4184 46.8725 59.5483 46.5109 60.4619 45.8088V47.582C59.4799 48.1909 58.3422 48.5016 57.187 48.4766Z"
      fill="white"
    />
    <path
      d="M66.7783 48.4753C66.2076 48.4953 65.6388 48.3994 65.1061 48.1935C64.5735 47.9876 64.0881 47.6759 63.6792 47.2772C63.2836 46.8984 62.9709 46.4415 62.761 45.9356C62.5511 45.4296 62.4485 44.8855 62.4598 44.3379C62.449 43.7919 62.5518 43.2496 62.7618 42.7454C62.9717 42.2412 63.2841 41.7862 63.6792 41.4092C64.0881 41.0105 64.5735 40.6989 65.1061 40.4929C65.6388 40.287 66.2076 40.1911 66.7783 40.2111C67.347 40.1944 67.9133 40.2918 68.4438 40.4976C68.9742 40.7034 69.4581 41.0133 69.8668 41.4092C70.2601 41.7872 70.571 42.2426 70.78 42.7466C70.9889 43.2506 71.0913 43.7924 71.0808 44.3379C71.0899 44.8848 70.9869 45.4277 70.7781 45.9332C70.5693 46.4388 70.2591 46.8962 69.8668 47.2772C69.4581 47.6731 68.9742 47.983 68.4438 48.1888C67.9133 48.3946 67.347 48.492 66.7783 48.4753ZM66.7783 46.9577C67.1196 46.9648 67.4587 46.9018 67.7746 46.7726C68.0906 46.6435 68.3768 46.451 68.6154 46.2069C68.8597 45.9623 69.0514 45.6703 69.1787 45.3489C69.306 45.0275 69.3662 44.6834 69.3556 44.3379C69.365 43.9941 69.3043 43.6519 69.177 43.3324C69.0497 43.0129 68.8586 42.7227 68.6154 42.4795C68.3768 42.2354 68.0906 42.0429 67.7746 41.9138C67.4587 41.7846 67.1196 41.7216 66.7783 41.7287C66.4345 41.7207 66.0928 41.7832 65.7741 41.9123C65.4554 42.0415 65.1665 42.2345 64.9253 42.4795C64.6827 42.7229 64.4924 43.0133 64.3661 43.3329C64.2397 43.6524 64.1799 43.9944 64.1904 44.3379C64.1788 44.6831 64.238 45.027 64.3644 45.3485C64.4907 45.6699 64.6816 45.9621 64.9253 46.2069C65.1679 46.4491 65.4574 46.6391 65.776 46.7655C66.0946 46.8918 66.4357 46.9518 66.7783 46.9418V46.9577Z"
      fill="white"
    />
    <path
      d="M75.5105 48.4752C74.9823 48.5056 74.4635 48.3256 74.0675 47.9747C73.8767 47.7649 73.7309 47.5182 73.6393 47.2498C73.5478 46.9814 73.5122 46.6971 73.535 46.4145V37.0586H75.2656V45.9192C75.2656 46.5582 75.5531 46.8777 76.1282 46.8777C76.3569 46.8843 76.5832 46.8291 76.7832 46.718V48.2036C76.3864 48.3934 75.9503 48.4864 75.5105 48.4752Z"
      fill="white"
    />
    <path
      d="M80.951 48.4752C80.4227 48.5056 79.9039 48.3256 79.5079 47.9747C79.3171 47.7649 79.1714 47.5182 79.0798 47.2498C78.9882 46.9814 78.9527 46.6971 78.9754 46.4145V37.0586H80.7007V45.9192C80.7007 46.5582 80.9936 46.8777 81.5687 46.8777C81.7974 46.8843 82.0236 46.8291 82.2236 46.718V48.2036C81.8268 48.3934 81.3907 48.4864 80.951 48.4752Z"
      fill="white"
    />
    <path
      d="M83.8862 44.3218C83.8687 43.7814 83.9585 43.2429 84.1505 42.7375C84.3424 42.232 84.6327 41.7697 85.0044 41.3771C85.3801 40.9871 85.8335 40.6803 86.3352 40.4766C86.837 40.273 87.376 40.177 87.9172 40.195C88.656 40.1728 89.3812 40.3977 89.9779 40.834C90.5313 41.2384 90.9736 41.776 91.2641 42.3968C91.5545 43.0176 91.6835 43.7017 91.6393 44.3857V44.833H85.6275C85.6705 45.1432 85.7774 45.441 85.9415 45.7078C86.1056 45.9745 86.3232 46.2043 86.5806 46.3825C87.1747 46.7746 87.8769 46.9702 88.5881 46.9417C89.478 46.943 90.3511 46.6999 91.1121 46.2388V47.6925C90.2512 48.242 89.2407 48.5099 88.2207 48.4593C87.082 48.4851 85.9767 48.0735 85.1323 47.3091C84.7216 46.9303 84.3976 46.4673 84.1825 45.9517C83.9675 45.4361 83.8664 44.8801 83.8862 44.3218ZM85.6434 43.5231H89.9034C89.8853 43.0355 89.6859 42.5722 89.3443 42.2238C89.1487 42.0275 88.9138 41.8747 88.655 41.7756C88.3962 41.6765 88.1193 41.6332 87.8426 41.6487C87.2893 41.6285 86.7489 41.8188 86.3304 42.1812C85.9349 42.5215 85.6883 43.0032 85.6434 43.5231Z"
      fill="white"
    />
    <path
      d="M97.8272 48.4749C97.2745 48.4876 96.7247 48.3912 96.2094 48.1911C95.694 47.991 95.2232 47.6911 94.824 47.3087C94.4209 46.9249 94.103 46.4606 93.8911 45.946C93.6792 45.4313 93.5779 44.8778 93.5939 44.3214C93.5756 43.7687 93.6759 43.2185 93.8881 42.7078C94.1003 42.1971 94.4194 41.7378 94.824 41.3608C95.6422 40.5988 96.7254 40.1861 97.8432 40.2106C98.6649 40.1896 99.4791 40.3725 100.213 40.7431V42.2288C99.5526 41.8915 98.8241 41.7094 98.0828 41.6963C97.3522 41.6749 96.6419 41.9384 96.102 42.4311C95.8432 42.6688 95.6381 42.9589 95.5004 43.2821C95.3627 43.6053 95.2954 43.9542 95.3032 44.3055C95.2943 44.6585 95.3609 45.0093 95.4987 45.3344C95.6364 45.6595 95.8421 45.9514 96.102 46.1905C96.6419 46.6832 97.3522 46.9467 98.0828 46.9253C98.8704 46.9393 99.6464 46.7347 100.325 46.3343V47.8039C99.566 48.2441 98.7043 48.4756 97.8272 48.4749Z"
      fill="white"
    />
    <path
      d="M103.848 46.1104V41.9197H102.171V40.4021H103.848V38.1177H105.579V40.4021H108.055V41.9197H105.579V45.6151C105.564 45.787 105.583 45.9602 105.635 46.1247C105.687 46.2892 105.771 46.4417 105.882 46.5736C106.009 46.6831 106.156 46.7664 106.315 46.8185C106.474 46.8706 106.642 46.8905 106.809 46.8771C107.22 46.8781 107.626 46.7794 107.991 46.5896V48.0752C107.46 48.3694 106.855 48.5026 106.25 48.4586C105.607 48.4951 104.976 48.277 104.493 47.8516C104.268 47.6232 104.095 47.35 103.984 47.0499C103.873 46.7498 103.827 46.4296 103.848 46.1104Z"
      fill="white"
    />
    <path
      d="M111.567 38.9968C111.423 38.9997 111.28 38.9734 111.146 38.9194C111.012 38.8654 110.891 38.7848 110.79 38.6826C110.689 38.5812 110.61 38.4606 110.557 38.3279C110.504 38.1953 110.478 38.0533 110.481 37.9105C110.477 37.7652 110.503 37.6207 110.556 37.4854C110.609 37.3502 110.688 37.2267 110.79 37.1224C110.891 37.0192 111.012 36.9376 111.145 36.8827C111.279 36.8278 111.423 36.8006 111.567 36.8029C111.708 36.8004 111.849 36.8275 111.979 36.8825C112.109 36.9375 112.226 37.0193 112.323 37.1224C112.425 37.2265 112.505 37.3497 112.559 37.485C112.613 37.6203 112.64 37.7649 112.637 37.9105C112.64 38.0537 112.613 38.1959 112.559 38.3286C112.505 38.4612 112.425 38.5816 112.323 38.6826C112.225 38.784 112.108 38.8642 111.978 38.9182C111.848 38.9723 111.708 38.999 111.567 38.9968ZM112.43 48.2514H110.704V40.4025H112.43V48.2514Z"
      fill="white"
    />
    <path
      d="M120.116 48.4749C119.582 48.5019 119.049 48.3998 118.562 48.1774C118.076 47.9549 117.65 47.6186 117.32 47.1969V48.2619H115.59V37.0796H117.32V41.4939C117.65 41.0722 118.076 40.7359 118.562 40.5135C119.049 40.291 119.582 40.189 120.116 40.216C120.632 40.2027 121.145 40.3031 121.618 40.5102C122.091 40.7172 122.513 41.0258 122.853 41.4141C123.57 42.218 123.951 43.2662 123.918 44.3428C123.953 45.4228 123.571 46.4749 122.853 47.2821C122.512 47.6694 122.09 47.977 121.617 48.1831C121.144 48.3892 120.632 48.4888 120.116 48.4749ZM119.653 46.9573C119.99 46.9661 120.324 46.9037 120.635 46.7744C120.946 46.645 121.227 46.4515 121.458 46.2065C121.935 45.7026 122.193 45.0309 122.177 44.3374C122.184 43.9981 122.124 43.6607 122.001 43.3445C121.878 43.0284 121.693 42.7397 121.458 42.495C121.229 42.2458 120.95 42.0484 120.638 41.9161C120.327 41.7838 119.991 41.7198 119.653 41.7282C119.168 41.7273 118.694 41.8639 118.284 42.1223C117.882 42.3645 117.549 42.7078 117.32 43.118V45.5515C117.545 45.9688 117.876 46.3196 118.279 46.5686C118.692 46.8218 119.169 46.9511 119.653 46.9413V46.9573Z"
      fill="white"
    />
    <path
      d="M128.343 48.4752C127.814 48.5056 127.296 48.3256 126.9 47.9747C126.709 47.7649 126.563 47.5182 126.471 47.2498C126.38 46.9814 126.344 46.6971 126.367 46.4145V37.0586H128.092V45.9192C128.092 46.5582 128.385 46.8777 128.96 46.8777C129.189 46.8843 129.415 46.8291 129.615 46.718V48.2036C129.218 48.3934 128.782 48.4864 128.343 48.4752Z"
      fill="white"
    />
    <path
      d="M131.278 44.3218C131.26 43.7814 131.35 43.2429 131.542 42.7375C131.734 42.232 132.024 41.7697 132.396 41.3771C132.772 40.9871 133.225 40.6803 133.727 40.4766C134.229 40.273 134.768 40.177 135.309 40.195C136.048 40.1728 136.773 40.3977 137.37 40.834C137.923 41.2384 138.365 41.776 138.656 42.3968C138.946 43.0176 139.075 43.7017 139.031 44.3857V44.833H133.019C133.062 45.1432 133.169 45.441 133.333 45.7078C133.497 45.9745 133.715 46.2043 133.972 46.3825C134.566 46.7746 135.269 46.9702 135.98 46.9417C136.87 46.943 137.743 46.6999 138.504 46.2388V47.6925C137.643 48.242 136.632 48.5099 135.612 48.4593C134.474 48.4851 133.368 48.0735 132.524 47.3091C132.113 46.9303 131.789 46.4673 131.574 45.9517C131.359 45.4361 131.258 44.8801 131.278 44.3218ZM133.035 43.5231H137.295C137.277 43.0355 137.077 42.5722 136.736 42.2238C136.54 42.0275 136.305 41.8747 136.047 41.7756C135.788 41.6765 135.511 41.6332 135.234 41.6487C134.681 41.6285 134.141 41.8188 133.722 42.1812C133.326 42.5215 133.08 43.0032 133.035 43.5231Z"
      fill="white"
    />
    <path
      d="M144.32 48.4751C143.173 48.5393 142.033 48.2613 141.045 47.6763V46.0469C141.987 46.6831 143.103 47.0123 144.24 46.9894C144.678 47.0123 145.114 46.9282 145.513 46.7445C145.646 46.6865 145.76 46.5915 145.841 46.4707C145.922 46.3499 145.967 46.2084 145.97 46.0629C145.959 45.9231 145.907 45.7896 145.821 45.6789C145.735 45.5682 145.619 45.4851 145.486 45.4399C145.242 45.3414 144.991 45.2613 144.735 45.2002L143.761 44.9606C143.029 44.8168 142.341 44.5071 141.748 44.0554C141.536 43.86 141.371 43.6202 141.263 43.3532C141.155 43.0861 141.108 42.7986 141.125 42.5112C141.115 42.179 141.191 41.8499 141.345 41.5553C141.499 41.2607 141.725 41.0104 142.003 40.8285C142.736 40.3779 143.589 40.1623 144.448 40.2108C145.461 40.1618 146.466 40.4059 147.344 40.9137V42.5112C146.485 42.0037 145.509 41.7284 144.511 41.7124C144.099 41.6902 143.687 41.7707 143.313 41.9467C143.191 42.0007 143.087 42.0886 143.014 42.2C142.94 42.3114 142.9 42.4416 142.898 42.5751C142.898 42.82 143.063 43.0277 143.393 43.1981C143.436 43.1981 143.686 43.3046 144.144 43.4377L145.055 43.6347C146.851 44.0288 147.747 44.8541 147.744 46.1108C147.747 46.455 147.662 46.7943 147.497 47.0963C147.332 47.3984 147.093 47.6531 146.801 47.8361C146.059 48.2987 145.193 48.5218 144.32 48.4751Z"
      fill="white"
    />
    <path
      d="M43.0228 4.56613L39.6787 32.6497L20.2109 7.13806C20.0763 6.96033 19.9228 6.79783 19.7529 6.65349L20.3227 1.86108C20.3697 1.46337 20.561 1.09673 20.8604 0.830732C21.1597 0.564732 21.5463 0.417877 21.9468 0.418031C22.0124 0.41274 22.0782 0.41274 22.1438 0.418031L41.5904 2.72904C41.8054 2.7547 42.0133 2.82271 42.2019 2.92914C42.3905 3.03556 42.5562 3.17829 42.6894 3.34909C42.8226 3.51989 42.9206 3.71538 42.9778 3.92427C43.035 4.13315 43.0503 4.35131 43.0228 4.56613Z"
      fill="url(#paint0_linear_2392_1833)"
    />
    <path
      d="M38.5247 37.3517L36.1977 39.1302L35.2872 39.8225L34.3713 40.52L33.6844 41.0525L32.8377 41.6968L32.7365 41.7714C32.2822 42.1051 31.8508 42.4352 31.4426 42.7618L31.3468 42.8364C28.1252 45.355 26.0698 47.4264 24.8237 49.1517C24.3207 49.8257 23.9022 50.5589 23.5777 51.3349C22.1133 54.9771 24.8397 56.6598 25.7556 57.9964C27.2572 60.2222 26.8791 63.9443 24.0836 64.5034C20.8886 65.1477 18.7587 61.9634 21.0217 56.8196C21.7279 55.1781 21.8074 53.3346 21.2454 51.6384C21.1135 51.1928 20.9552 50.7554 20.7715 50.3285C20.6596 50.0676 20.5372 49.796 20.4094 49.5404C20.2816 49.2848 20.1538 49.0505 20.0153 48.8056C19.6958 48.2252 19.3391 47.6448 18.9504 47.0697C18.8013 46.8407 18.6468 46.617 18.4924 46.3881C17.6351 45.1314 16.7086 43.88 15.83 42.6606V42.6234L0.334466 22.3248C0.0717577 21.9793 -0.0432318 21.5437 0.0146596 21.1135C0.072551 20.6833 0.298611 20.2936 0.64332 20.0298L16.208 8.14991C16.4957 7.93031 16.8472 7.81065 17.2091 7.80912C17.4774 7.80729 17.7417 7.87518 17.9759 8.00614C18.1841 8.11433 18.3659 8.26704 18.5084 8.45343L18.7001 8.7037L19.3923 9.61426L38.8229 35.0726C39.0805 35.4163 39.1934 35.847 39.1377 36.2729C39.082 36.6987 38.862 37.0859 38.5247 37.3517Z"
      fill="url(#paint1_linear_2392_1833)"
    />
    <path
      d="M31.4326 42.7565C31.8409 42.4335 32.2722 42.1034 32.7266 41.7661L31.4326 42.7565Z"
      fill="url(#paint2_linear_2392_1833)"
    />
    <path
      d="M32.8379 41.6923L33.6859 41.0469C33.6229 41.109 33.5552 41.166 33.4833 41.2176L32.8379 41.6923Z"
      fill="white"
    />
    <path
      d="M32.8379 41.6923L33.4833 41.2176C33.5552 41.166 33.6229 41.109 33.6859 41.0469L32.8379 41.6923Z"
      fill="white"
    />
    <path
      d="M33.6859 41.0469C33.6229 41.109 33.5552 41.166 33.4833 41.2176L32.8379 41.6923L33.6859 41.0469Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2392_1833"
        x1="19.7529"
        y1="16.5346"
        x2="43.0361"
        y2="16.5346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D22A82" />
        <stop offset="1" stopColor="#EC5B39" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2392_1833"
        x1="0"
        y1="36.2023"
        x2="39.1516"
        y2="36.2023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D22A82" />
        <stop offset="1" stopColor="#EC5B39" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2392_1833"
        x1="31.4326"
        y1="42.2614"
        x2="32.7266"
        y2="42.2614"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D22A82" />
        <stop offset="1" stopColor="#EC5B39" />
      </linearGradient>
    </defs>
  </Svg>
)

export default Logo
