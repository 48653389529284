import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Liquid Collectibles',
  description:
    'Join the revolution of NFT indexes and farming in Binance Smart Chain. Making NFTs more accessible to people.',
  image: 'https://liquidcollectibles.io/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Liquid Collectibles')}`,
      }
    case '/yield':
      return {
        title: `${t('Yield')} | ${t('Liquid Collectibles')}`,
      }
    case '/stake':
      return {
        title: `${t('Stake')} | ${t('Liquid Collectibles')}`,
      }
    case '/Collectibless':
      return {
        title: `${t('Collectibless')} | ${t('Liquid Collectibles')}`,
      }
    case '/sale':
      return {
        title: `${t('Public Sale')} | ${t('Liquid Collectibles')}`,
      }
    case '/collections':
      return {
        title: `${t('Collections')} | ${t('Liquid Collectibles')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Liquid Collectibles')}`,
      }
    default:
      return null
  }
}
