import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, Text, NotificationDot } from '@liquidcollectibles/uikit'
import { useTranslation } from 'contexts/Localization'

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Text} {
    margin-left: 8px;
  }
`

const ViewControls = styled.div`
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
  width: auto;
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;

    > div {
      padding: 0;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 160px;
  margin: 4px 4px 4px 0px;

  a {
    padding-left: 12px;
    padding-right: 12px;
    height: 42px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0px 6px 0px 4px;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PoolTabButtons = ({ stakedOnly, setStakedOnly, hasStakeInFinishedPools }) => {
  const { url, isExact } = useRouteMatch()
  const { t } = useTranslation()

  const liveOrFinishedSwitch = (
    <Wrapper>
      <ButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" variant="subtle">
        <ButtonMenuItem as={Link} to={`${url}`}>
          {t('Active')}
        </ButtonMenuItem>
        <NotificationDot show={hasStakeInFinishedPools}>
          <ButtonMenuItem as={Link} to={`${url}/history`}>
            {t('Finished')}
          </ButtonMenuItem>
        </NotificationDot>
      </ButtonMenu>
    </Wrapper>
  )

  const stakedOnlySwitch = (
    <ToggleWrapper>
      {/* COMMENT OUT TO ENABLE STAKED ONLY BUTTON
      
      <Toggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
      <Text> {t('Staked only')}</Text>
      */}
    </ToggleWrapper>
  )

  return (
    <ViewControls>
      {stakedOnlySwitch}
      {liveOrFinishedSwitch}
    </ViewControls>
  )
}

export default PoolTabButtons
