import tokens from './tokens'
import farms from './farms'
import { Ifo, Token } from './types'

const licoBnbLpToken: Token = {
  symbol: farms[1].lpSymbol,
  address: farms[1].lpAddresses,
  decimals: 18,
}

const ifos: Ifo[] = [
  {
    id: 'lico',
    address: '0x34DcA727049d2C40Cdc06e011957db272A3C963a',
    isActive: true,
    name: 'LICO (LICO)',
    saleAmountOne: '10,000,000 LICO',
    saleAmountTwo: '333,000 LIMO',
    poolBasic: {
      saleAmount: '375,000 KALM',
      raiseAmount: '$750,000',
      licoToBurn: '$375,000',
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: '875,000 KALM',
      raiseAmount: '2,500 BNB',
      licoToBurn: '$1,250,000',
      distributionRatio: 0.7,
    },
    currency: licoBnbLpToken,
    tokens: [tokens.lico, tokens.limo],
    releaseBlockNumber: 12060493,
    campaignId: '511110000',
    articleUrl: 'https://docs.liquidcollectibles.io/tokenomics/the-lico-token',
    tokenOfferingPrice: 0.0001875,
    tokenOfferingTwoPrice: 0.001875,
    version: 2,
  },
  /* {
    id: 'hotcross',
    address: '0xb664cdbe385656F8c54031c0CB12Cea55b584b63',
    isActive: false,
    name: 'Hot Cross (HOTCROSS)',
    poolBasic: {
      saleAmount: '15,000,000 HOTCROSS',
      raiseAmount: '$750,000',
      licoToBurn: '$375,000',
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: '35,000,000 HOTCROSS',
      raiseAmount: '$1,750,000',
      licoToBurn: '$875,000',
      distributionRatio: 0.7,
    },
    currency: licoBnbLpToken,
    token: tokens.hotcross,
    releaseBlockNumber: 7477900,
    campaignId: '511100000',
    articleUrl: 'https://pancakeswap.medium.com/hot-cross-hotcross-ifo-to-be-hosted-on-pancakeswap-10e70f1f6841',
    tokenOfferingPrice: 0.05,
    version: 2,
  }, */
]

export default ifos
