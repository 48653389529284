import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.lico,
    earningToken: tokens.lico,
    contractAddress: {
      97: '0xb3369AbBbB93c26cf8CCB942757d9B41E5be6C18',
      56: '0x25ef2439ee92552b6f58463394e66d289f9e7b7c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    // TODO dynamic this
    tokenPerBlock: '5',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: tokens.lico,
    earningToken: tokens.limo,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x86a88863721e4823a2c7130a856ee4f97e72fb60',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00002546296296',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: tokens.lico,
    earningToken: tokens.nfai,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x4f1597F0aCf58Cf9f6451a1F13487663F8609B26',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000162037037',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 14,
    stakingToken: tokens.lico,
    earningToken: tokens.mpntfi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x41dd7ccfcb1219c2ba59ed61f853cf95c5c43b36',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00003587962963',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 6,
    stakingToken: tokens.lico,
    earningToken: tokens.nfbi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xa1d27df66f1769e1e17447eea612f3219e801e14',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00001157407407',
    sortOrder: 3,
    isFinished: false,
  },
  {
    sousId: 11,
    stakingToken: tokens.lico,
    earningToken: tokens.nfmi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x0b803737c41Fc8A2f1dd9fff34324FFD470dEBD8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0002314814815',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 12,
    stakingToken: tokens.lico,
    earningToken: tokens.mooni,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x9f22306d7711d3291e9ada134e1c515631be3cbf',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0001886574074',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: tokens.lico,
    earningToken: tokens.bulli,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xdaa9d650134318b7293452e54883da97049e6d62',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0004805555556',
    sortOrder: 3,
    isFinished: false,
  },
  {
    sousId: 4,
    stakingToken: tokens.lico,
    earningToken: tokens.npi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x4e7a1d70f89c4ac81367b24add1f3e876af5635d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000003472222222',
    sortOrder: 4,
    isFinished: false,
  },
  {
    sousId: 10,
    stakingToken: tokens.lico,
    earningToken: tokens.cbi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x3F1c5f6D570c8053D7D93668b1593239Bc6F7B0E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00003356481481',
    sortOrder: 4,
    isFinished: false,
  },
  {
    sousId: 13,
    stakingToken: tokens.lico,
    earningToken: tokens.hoboi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xe4551ed062a49ee0a112ee41e33dd0b5abcf5d7b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00002777777778',
    sortOrder: 4,
    isFinished: false,
  },
  {
    sousId: 5,
    stakingToken: tokens.lico,
    earningToken: tokens.bearzi,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xb7c2e7becad95a85009a708ad0cd146b2ae38080',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000006944444444',
    sortOrder: 5,
    isFinished: false,
  },
  {
    sousId: 7,
    stakingToken: tokens.lico,
    earningToken: tokens.wofai,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xc52d169bb066b1f4fa543249657b17b2ca12f691',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00009606481481',
    sortOrder: 6,
    isFinished: true,
  },
  {
    sousId: 8,
    stakingToken: tokens.lico,
    earningToken: tokens.extpunki,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0x6279c016441e314834a35307e75d1886afd6ab9c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000162962963',
    sortOrder: 7,
    isFinished: false,
  },
  {
    sousId: 9,
    stakingToken: tokens.lico,
    earningToken: tokens.hifii,
    contractAddress: {
      97: '0x0d1cbe77b975e7962ee974e2f358021785085445',
      56: '0xe7ae88275b53708be14c39f297908acea0febb45',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00009606481481',
    sortOrder: 8,
    isFinished: true,
  },
]

export default pools
