import React from 'react'
import { TokenPairImage, ImageProps } from '@liquidcollectibles/uikit'
import tokens from 'config/constants/tokens'
import { getAddress } from 'utils/addressHelpers'

const LicoVaultTokenPairImage: React.FC<Omit<ImageProps, 'src'>> = (props) => {
  const primaryTokenSrc = `/images/tokens/${getAddress(tokens.lico.address)}.svg`

  return (
    <TokenPairImage
      variant="inverted"
      secondarySrc={primaryTokenSrc}
      primarySrc="/images/tokens/autorenew.svg"
      {...props}
    />
  )
}

export default LicoVaultTokenPairImage
