import { MenuEntry } from '@liquidcollectibles/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Yield'),
    icon: 'YieldIcon',
    href: '/yield',
  },
  {
    label: t('Stake'),
    icon: 'StakeIcon',
    href: '/stake',
  },
  {
    label: t('Collections'),
    icon: 'CollectIcon',
    href: '/collections',
  },
  {
    label: t('INO'),
    icon: 'NftIcon',
    href: '/ino',
  },
  {
    label: 'More',
    items: [
      {
        label: 'Price',
        href: 'https://charts.bogged.finance/0x4F3266a56589357B4f8082918b14B923693e57f0',
      },
      {
        label: 'Sale',
        href: '/sale',
      },
      {
        label: 'Docs',
        href: 'https://docs.liquidcollectibles.io/',
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/LiqCollectibles',
      },
      {
        label: 'Telegram',
        href: 'https://t.me/LiquidCollectibles',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/tR8hMsDhQh',
      },
      {
        label: 'Feedback',
        href: 'https://liquid-collectibles.nolt.io/',
      },
    ],
  },
]

export const mobLinks = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Yield',
    href: '/yield',
  },
  {
    label: 'Stake',
    href: '/stake',
  },
  {
    label: 'Collections',
    href: '/collections',
  },
  {
    label: 'INO',
    href: '/ino',
  },
  {
    label: 'Sale',
    href: '/sale',
  },
  {
    label: 'Docs',
    href: 'https://docs.liquidcollectibles.io/',
  },
  {
    label: 'Price',
    href: 'https://twitter.com/price',
  },
]

export default config
