import { Nft, NftSource, NftType } from './types'

export const IPFS_GATEWAY = 'https://cloudflare-ipfs.com'

export const nftSources: NftSource = {
  [NftType.PANCAKE]: {
    address: {
      56: '0x0d464bdde2301c30871bb4c29bb7dd935f5a985c',
      97: '0x3df093caa56792a83376be978b907cce88d03441',
    },
    identifierKey: 'image',
  },
  [NftType.MIXIE]: {
    address: {
      56: '0xa251b5EAa9E67F2Bc8b33F33e20E91552Bf85566',
      97: '',
    },
    identifierKey: 'image',
  },
}

/**
 * NOTE: https://cloudflare-ipfs.com does not support video streaming so for the video URLS we need to use
 * https://gateway.pinata.cloud
 */

const Nfts: Nft[] = [
  {
    name: 'Liquid Monster #1',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/1.jpeg',
      md: 'liquid-monsters/1.jpeg',
      sm: 'liquid-monsters/1.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '1',
    type: NftType.PANCAKE,
    variationId: 1,
  },
  {
    name: 'Liquid Monster #2',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/2.jpeg',
      md: 'liquid-monsters/2.jpeg',
      sm: 'liquid-monsters/2.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '2',
    type: NftType.PANCAKE,
    variationId: 2,
  },
  {
    name: 'Liquid Monster #3',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/3.jpeg',
      md: 'liquid-monsters/3.jpeg',
      sm: 'liquid-monsters/3.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '3',
    type: NftType.PANCAKE,
    variationId: 3,
  },
  {
    name: 'Liquid Monster #4',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/4.jpeg',
      md: 'liquid-monsters/4.jpeg',
      sm: 'liquid-monsters/4.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '4',
    type: NftType.PANCAKE,
    variationId: 4,
  },
  {
    name: 'Liquid Monster #5',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/5.jpeg',
      md: 'liquid-monsters/5.jpeg',
      sm: 'liquid-monsters/5.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '5',
    type: NftType.PANCAKE,
    variationId: 5,
  },
  {
    name: 'Liquid Monster #6',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/6.jpeg',
      md: 'liquid-monsters/6.jpeg',
      sm: 'liquid-monsters/6.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '6',
    type: NftType.PANCAKE,
    variationId: 6,
  },
  {
    name: 'Liquid Monster #7',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/7.jpeg',
      md: 'liquid-monsters/7.jpeg',
      sm: 'liquid-monsters/7.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '7',
    type: NftType.PANCAKE,
    variationId: 7,
  },
  {
    name: 'Liquid Monster #8',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/8.jpeg',
      md: 'liquid-monsters/8.jpeg',
      sm: 'liquid-monsters/8.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '8',
    type: NftType.PANCAKE,
    variationId: 8,
  },
  {
    name: 'Liquid Monster #9',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/9.jpeg',
      md: 'liquid-monsters/9.jpeg',
      sm: 'liquid-monsters/9.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '9',
    type: NftType.PANCAKE,
    variationId: 9,
  },
  {
    name: 'Liquid Monster #10',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/10.jpeg',
      md: 'liquid-monsters/10.jpeg',
      sm: 'liquid-monsters/10.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '10',
    type: NftType.PANCAKE,
    variationId: 10,
  },
  {
    name: 'Liquid Monster #11',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/11.jpeg',
      md: 'liquid-monsters/11.jpeg',
      sm: 'liquid-monsters/11.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '11',
    type: NftType.PANCAKE,
    variationId: 11,
  },
  {
    name: 'Liquid Monster #12',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/12.jpeg',
      md: 'liquid-monsters/12.jpeg',
      sm: 'liquid-monsters/12.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '12',
    type: NftType.PANCAKE,
    variationId: 12,
  },
  {
    name: 'Liquid Monster #13',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/13.jpeg',
      md: 'liquid-monsters/13.jpeg',
      sm: 'liquid-monsters/13.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '13',
    type: NftType.PANCAKE,
    variationId: 13,
  },
  {
    name: 'Liquid Monster #14',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/14.jpeg',
      md: 'liquid-monsters/14.jpeg',
      sm: 'liquid-monsters/14.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '14',
    type: NftType.PANCAKE,
    variationId: 14,
  },
  {
    name: 'Liquid Monster #15',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/16.jpeg',
      md: 'liquid-monsters/16.jpeg',
      sm: 'liquid-monsters/16.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '16',
    type: NftType.PANCAKE,
    variationId: 16,
  },
  {
    name: 'Liquid Monster #17',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/17.jpeg',
      md: 'liquid-monsters/17.jpeg',
      sm: 'liquid-monsters/17.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '17',
    type: NftType.PANCAKE,
    variationId: 17,
  },
  {
    name: 'Liquid Monster #18',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/18.jpeg',
      md: 'liquid-monsters/18.jpeg',
      sm: 'liquid-monsters/18.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '18',
    type: NftType.PANCAKE,
    variationId: 18,
  },
  {
    name: 'Liquid Monster #19',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/19.jpeg',
      md: 'liquid-monsters/19.jpeg',
      sm: 'liquid-monsters/19.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '19',
    type: NftType.PANCAKE,
    variationId: 19,
  },
  {
    name: 'Liquid Monster #20',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/20.jpeg',
      md: 'liquid-monsters/20.jpeg',
      sm: 'liquid-monsters/20.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '20',
    type: NftType.PANCAKE,
    variationId: 20,
  },
  {
    name: 'Liquid Monster #21',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/21.jpeg',
      md: 'liquid-monsters/21.jpeg',
      sm: 'liquid-monsters/21.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '21',
    type: NftType.PANCAKE,
    variationId: 21,
  },
  {
    name: 'Liquid Monster #22',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/22.jpeg',
      md: 'liquid-monsters/22.jpeg',
      sm: 'liquid-monsters/22.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '22',
    type: NftType.PANCAKE,
    variationId: 22,
  },
  {
    name: 'Liquid Monster #23',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/23.jpeg',
      md: 'liquid-monsters/23.jpeg',
      sm: 'liquid-monsters/23.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '23',
    type: NftType.PANCAKE,
    variationId: 23,
  },
  {
    name: 'Liquid Monster #24',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/24.jpeg',
      md: 'liquid-monsters/24.jpeg',
      sm: 'liquid-monsters/24.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '24',
    type: NftType.PANCAKE,
    variationId: 24,
  },
  {
    name: 'Liquid Monster #25',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/25.jpeg',
      md: 'liquid-monsters/25.jpeg',
      sm: 'liquid-monsters/25.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '25',
    type: NftType.PANCAKE,
    variationId: 25,
  },
  {
    name: 'Liquid Monster #26',
    description: 'Can Claire the clairvoyant see what’s in the cards for you?',
    images: {
      lg: 'liquid-monsters/26.jpeg',
      md: 'liquid-monsters/26.jpeg',
      sm: 'liquid-monsters/26.jpeg',
      ipfs: 'https://cloudflare-ipfs.com/ipfs/QmRa2WbGnqdgUzrYXxZWv549BDxq3heYridJeoWGcebcU8/claire.png',
    },
    sortOrder: 999,
    identifier: '26',
    type: NftType.PANCAKE,
    variationId: 26,
  },
]

export default Nfts
