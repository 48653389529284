import React from 'react'

const DiscordSvg = (props) => (
  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" height="44px" {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={22.89}
        x2={44}
        y2={22.89}
        gradientTransform="matrix(1 0 0 -1 0 44.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d22a82" />
        <stop offset={1} stopColor="#ec5b39" />
      </linearGradient>
      <clipPath id="b">
        <path
          style={{
            fill: 'none',
          }}
          d="M10 10h24v24H10z"
        />
      </clipPath>
    </defs>
    <circle
      cx={22}
      cy={22}
      r={21.5}
      style={{
        stroke: 'url(#a)',
        fill: 'none',
      }}
    />
    <g
      style={{
        clipPath: 'url(#b)',
      }}
    >
      <path
        d="M30.32 14.49A20 20 0 0 0 25.43 13a12.67 12.67 0 0 0-.6 1.23 18.71 18.71 0 0 0-5.49 0 11.8 11.8 0 0 0-.7-1.23h-.05a20 20 0 0 0-4.88 1.49A19.64 19.64 0 0 0 10.1 28a20.12 20.12 0 0 0 6 3h.05a14.23 14.23 0 0 0 1.22-2 .08.08 0 0 0 0-.11 12.24 12.24 0 0 1-1.85-.89c.13-.09.25-.19.37-.29H16a14.36 14.36 0 0 0 12-.11l.37.29a11.81 11.81 0 0 1-1.87.88V29a16.53 16.53 0 0 0 1.23 2 20.11 20.11 0 0 0 6-3 19.56 19.56 0 0 0-3.55-13.44ZM18 25.28a2.39 2.39 0 0 1 0-4.76 2.26 2.26 0 0 1 2.16 2.38A2.28 2.28 0 0 1 18 25.28Zm8 0a2.39 2.39 0 0 1 0-4.76 2.25 2.25 0 0 1 2.15 2.38A2.26 2.26 0 0 1 26 25.28Z"
        style={{
          fill: '#fff',
          fillOpacity: 0.87,
        }}
      />
    </g>
  </svg>
)

export default DiscordSvg
