import React from 'react'
import { Menu as UikitMenu } from '@liquidcollectibles/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceLicoBusd } from 'state/farms/hooks'
import config, { mobLinks } from './config'
import UserMenu from './UserMenu'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const licoPriceUsd = usePriceLicoBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      backgroundColor="#121212"
      userMenu={<UserMenu />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      licoPriceUsd={licoPriceUsd.toNumber()}
      cakePriceUsd={licoPriceUsd.toNumber()}
      links={config(t)}
      // bannerIsVisible
      // bannerBoldText="Ugliens now listed!"
      // bannerLinkText="Get a Uglien"
      // bannerLink="/collection/0x16f2afe838ca987a15f4fafeebb2635732923439"
      mobileLinks={mobLinks}
      {...props}
    />
  )
}

export default Menu
