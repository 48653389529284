function track({ event, data, value }: { event: string; data: any; value?: number | string }): void {
  dataLayer?.push({ event, value, ...data })
}

export function trackEvent({
  value,
  category,
  action,
  label,
}: {
  category: string
  label: string
  action: string
  value?: number | string
}): void {
  dataLayer?.push({ event: 'trackEvent', value, category, action, label })
}

export default track
