import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'LICO',
    lpAddresses: {
      97: '0x01b0e2e751f745d3c5d09952cc1c7550adb6a329',
      56: '0x4F3266a56589357B4f8082918b14B923693e57f0',
    },
    token: tokens.lico,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'LICO-BNB LP',
    lpAddresses: {
      97: '0xee60c62c4fa35884f3fdd119b357741da8490507',
      56: '0xb447b224d96389b67f6c2659501ba996ba712bac',
    },
    token: tokens.lico,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xd05176C5A049C0ACF946D8992016dE4c9A9761a1',
      rewardToken: tokens.limo,
      earnLabel: 'LICO + LIMOi',
    },
  },
  {
    pid: 2,
    lpSymbol: 'LICO-BUSD LP',
    lpAddresses: {
      97: '0x8ef976c5f9b58f911c1403635a37937f8278c42f',
      56: '0x6f78c84e302f0aab14788d0b22c343efd3d0a52e',
    },
    token: tokens.lico,
    quoteToken: tokens.busd,
    dual: {
      rewarderAddress: '0xd05176C5A049C0ACF946D8992016dE4c9A9761a1',
      rewardToken: tokens.limo,
      earnLabel: 'LICO + LIMOi',
    },
  },
  {
    pid: 3,
    lpSymbol: 'LIMOi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x33a96c5cc9dffa7d5faa360e4e437b866eead170',
    },
    token: tokens.limo,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xd05176C5A049C0ACF946D8992016dE4c9A9761a1',
      rewardToken: tokens.limo,
      earnLabel: 'LICO + LIMOi',
    },
  },
  {
    pid: 4,
    lpSymbol: 'NFAi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x11f9856db037cf6bde94841d3ff8e08386844379',
    },
    token: tokens.nfai,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x8a705633b3f4D2Eba7def4D9d83ad9acFf1ce935',
      rewardToken: tokens.nfai,
      earnLabel: 'LICO + NFAi',
    },
  },
  {
    pid: 10,
    lpSymbol: 'NFBi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x74e72182a1276543bf9308805809249de93f1c24',
    },
    token: tokens.nfbi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xed698d93a79d7bcf1ade09d9bbf5fcbf7ef1e844',
      rewardToken: tokens.nfbi,
      earnLabel: 'LICO + NFBi',
    },
  },
  {
    pid: 21,
    lpSymbol: 'MPNFTi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xef44b976e43eac23b82f15266761f691ef5eb624',
    },
    token: tokens.mpntfi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xf19f46f6c8dde051daa5d705e10f7d3b9129e7d4',
      rewardToken: tokens.mpntfi,
      earnLabel: 'LICO + MPNFTi',
    },
  },
  {
    pid: 15,
    lpSymbol: 'NFMi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x4638cf540676f34c87244ede338df717061fbbe5',
    },
    token: tokens.nfmi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x8aa6b5982aa2b0efa13b59d5f787d7ac841822b7',
      rewardToken: tokens.nfmi,
      earnLabel: 'LICO + NFMi',
    },
  },
  {
    pid: 16,
    lpSymbol: 'MOONi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xa79fdf321649e66144959aac0c806602ec70aa02',
    },
    token: tokens.mooni,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x518ca86ae7cbf0236cd11a8b968792cc6e2186fb',
      rewardToken: tokens.mooni,
      earnLabel: 'LICO + MOONi',
    },
  },
  {
    pid: 7,
    lpSymbol: 'BULLi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x05037fc6a7e453cf34eb23164c63da92dca1e46a',
    },
    token: tokens.bulli,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x0F8272c0bf9dDd83D269E9a2609AfF72aa1f510d',
      rewardToken: tokens.bulli,
      earnLabel: 'LICO + BULLi',
    },
  },
  {
    pid: 8,
    lpSymbol: 'NPi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x593ede9cdeb02a65a24c28e6c4f92c7378201a05',
    },
    token: tokens.npi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x1652b97905EdE90Cd82bE3b92EAAA842f081738E',
      rewardToken: tokens.npi,
      earnLabel: 'LICO + NPi',
    },
  },
  {
    pid: 17,
    lpSymbol: 'TTICi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x4ff1e6f04af110401ce18d2599cad16625523ef9',
    },
    token: tokens.ttici,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x93B381360f24329B228160F8Afb13445d5CF82E6',
      rewardToken: tokens.ttici,
      earnLabel: 'LICO + TTICi',
    },
  },
  {
    pid: 18,
    lpSymbol: 'HOBOi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xcb03eb20b32c817cc9b2a34fc106c43627c287ad',
    },
    token: tokens.hoboi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x0cf6d26b74e5a66e37e4f0c30ad3e5f266ab8691',
      rewardToken: tokens.hoboi,
      earnLabel: 'LICO + HOBOi',
    },
  },
  {
    pid: 9,
    lpSymbol: 'BEARZi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x9322228000a3a2e791c8534063f22dadb20106e3',
    },
    token: tokens.bearzi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xc6824da92dcb8c7afd8a673917777a2c98671c05',
      rewardToken: tokens.bearzi,
      earnLabel: 'LICO + BEARZi',
    },
  },
  {
    pid: 11,
    lpSymbol: 'WOFAi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xfec16d12e3886e97d423188b70bb50bedebe5373',
    },
    token: tokens.wofai,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x8b836f0b02e6832430833a34ad1b10ea6bc7da70',
      rewardToken: tokens.wofai,
      earnLabel: 'LICO + WOFAi',
    },
  },
  {
    pid: 12,
    lpSymbol: 'EXTPUNKi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x0380090a53c465bd945d2a8c538eb66d5e8a556a',
    },
    token: tokens.extpunki,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x3ca1637a96f468a572b66ff3cc57e4c75fdea038',
      rewardToken: tokens.extpunki,
      earnLabel: 'LICO + EXTPUNKi',
    },
  },
  {
    pid: 13,
    lpSymbol: 'HIFIi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x5736716d7f8a0bd8717b9b7397a6c840d23c151b',
    },
    token: tokens.hifii,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xf2f7d07276c4bad5bd5df465c0020f1ee778c3dd',
      rewardToken: tokens.hifii,
      earnLabel: 'LICO + HIFIi',
    },
  },
  {
    pid: 14,
    lpSymbol: 'CBi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xed1c8f4a862e4a9b0d500591aaa90f2e31646b37',
    },
    token: tokens.cbi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x58566C194f2C1Edbb7F6B677737Cb3a5101fCc64',
      rewardToken: tokens.cbi,
      earnLabel: 'LICO + CBi',
    },
  },
  {
    pid: 19,
    lpSymbol: 'UGLIENi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0x3270615d41ae4b5b5fa8a1c1080d2ded1281ef42',
    },
    token: tokens.uglieni,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0xcf7ae47fd17223cc7369207ae589005812fd3447',
      rewardToken: tokens.uglieni,
      earnLabel: 'LICO + UGLIENi',
    },
  },
  {
    pid: 20,
    lpSymbol: 'IVGHi-BNB LP',
    lpAddresses: {
      97: '0x91c5b7470e5e322457380237e5222aeacf83a042',
      56: '0xac98b4ea192b22960e43508cd66aa3c7e1505277',
    },
    token: tokens.ivghi,
    quoteToken: tokens.wbnb,
    dual: {
      rewarderAddress: '0x61b6dd49f1472a39408fd70d9e27601a53f8cf25',
      rewardToken: tokens.ivghi,
      earnLabel: 'LICO + IVGHi',
    },
  },
]

export default farms
