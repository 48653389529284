import React from 'react'
import { CardHeader, Heading, Text, Flex } from '@liquidcollectibles/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Token } from 'config/constants/types'
import { TokenPairImage } from 'components/TokenImage'
import LicoVaultTokenPairImage from '../LicoVaultCard/LicoVaultTokenPairImage'

const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
`

const StyledCardHeader: React.FC<{
  earningToken: Token
  stakingToken: Token
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
}> = ({ earningToken, stakingToken, isFinished = false, isAutoVault = false }) => {
  const { t } = useTranslation()
  const isLicoPool = earningToken.symbol === 'LICO' && stakingToken.symbol === 'LICO'
  const background = 'transparent'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isLicoPool) {
      // manual lico
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isLicoPool) {
      return t('Earn LICO, stake LICO')
    }
    return t('Stake %symbol%', { symbol: stakingToken.symbol })
  }

  return (
    <Wrapper isFinished={isFinished} background={background}>
      <Flex flexDirection="column-reverse" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Heading color={isFinished ? 'textDisabled' : 'body'} scale="lg" mt="4px">
            {`${getHeadingPrefix()} ${earningToken.symbol}`}
          </Heading>
          <Text color={isFinished ? 'textDisabled' : 'textSubtle'}>{getSubHeading()}</Text>
        </Flex>
        {isAutoVault ? (
          <LicoVaultTokenPairImage width={72} height={72} />
        ) : (
          <TokenPairImage
            variant="inverted"
            secondaryToken={earningToken}
            primaryToken={stakingToken}
            width={72}
            height={72}
          />
        )}
      </Flex>
    </Wrapper>
  )
}

export default StyledCardHeader
