import rewarderAbi from 'config/abi/rewarder.json'
import multicall from 'utils/multicall'
import { SerializedBigNumber } from '../types'

export interface PublicRewarderData {
  rewardPerSecond: SerializedBigNumber
  totalAllocPoint: SerializedBigNumber
  rewardToken: string
  poolRewardPerSecond: number
  poolWeight: number
}

const fetchRewarder = async (rewarderAddress: string, pid: number): Promise<PublicRewarderData> => {
  try {
    const calls = [
      {
        address: rewarderAddress,
        name: 'rewardPerSecond',
      },
      {
        address: rewarderAddress,
        name: 'totalAllocPoint',
      },
      {
        address: rewarderAddress,
        name: 'poolInfo',
        params: [pid],
      },
      {
        address: rewarderAddress,
        name: 'rewardToken',
      },
    ]

    const [rewardPerSecond, totalAllocPoint, poolInfo, rewardToken] = await multicall(rewarderAbi, calls)

    const poolWeight = poolInfo.allocPoint / totalAllocPoint

    const poolRewardPerSecond = poolWeight * rewardPerSecond

    return {
      rewardPerSecond: rewardPerSecond[0].toJSON(),
      totalAllocPoint: totalAllocPoint[0].toJSON(),
      rewardToken: rewardToken[0],
      poolRewardPerSecond,
      poolWeight,
    }
  } catch (e) {
    console.error(e)
    console.warn('Something went wrong fetching reward address', rewarderAddress)
    return {
      rewardPerSecond: '0',
      totalAllocPoint: '0',
      rewardToken: '0',
      poolRewardPerSecond: 0,
      poolWeight: 1,
    }
  }
}

export default fetchRewarder
