import React, { lazy, useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@liquidcollectibles/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollFarmsData } from 'state/farms/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { useWeb3React } from '@web3-react/core'
import track from 'utils/track'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
// Views included in the main bundle
import Pools from './views/Pools'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))
const Wallet = lazy(() => import('./views/Wallet'))
const Collectibles = lazy(() => import('./views/Collectibles'))
const Collections = lazy(() => import('./views/Collections'))
const NftDetails = lazy(() => import('./views/Collections/nftDetails'))
const Ifos = lazy(() => import('./views/Ifos'))
const Inos = lazy(() => import('./views/Inos'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account } = useWeb3React()

  useEffect(() => {
    if (account)
      track({ event: 'event', data: { category: 'Wallet', action: 'Connect', walletAddress: account, label: account } })
  }, [account])

  usePollBlockNumber()
  useEagerConnect()
  // usePollCoreFarmData()
  usePollFarmsData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/yield">
              <Farms />
            </Route>
            <Route path="/stake">
              <Pools />
            </Route>
            <Route path="/wallet/:wallet">
              <Wallet />
            </Route>
            <Route path="/collectibles">
              <Collectibles />
            </Route>
            <Route path="/collection/:contractAddress/token/:tokenId">
              <NftDetails />
            </Route>
            <Route path="/collection/:contractAddress">
              <Collectibles />
            </Route>
            <Route path="/collections">
              <Collections />
            </Route>
            <Route path="/sale">
              <Ifos />
            </Route>
            <Route path="/ino">
              <Inos />
            </Route>

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}

            {/* Redirect */}

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
