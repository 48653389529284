import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, BSC_BLOCK_TIME, LICO_PER_SECOND, LICO_PER_YEAR, SECONDS_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new lico allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new lico allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getLicoPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  poolWeight: number,
): number => {
  const tokenPerBlock = poolWeight * LICO_PER_SECOND.toNumber() * BSC_BLOCK_TIME
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param licoPriceUsd Lico price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  licoPriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
): { licoRewardsApr: number; lpRewardsApr: number } => {
  const yearlyLicoRewardAllocation = LICO_PER_YEAR.times(poolWeight)
  const licoRewardsApr = yearlyLicoRewardAllocation.times(licoPriceUsd).div(poolLiquidityUsd).times(100)
  let licoRewardsAprAsNumber = null
  if (!licoRewardsApr.isNaN() && licoRewardsApr.isFinite()) {
    licoRewardsAprAsNumber = licoRewardsApr.toNumber()
  }
  const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
  return { licoRewardsApr: licoRewardsAprAsNumber, lpRewardsApr }
}

/**
 * Get farm APR value in %
 * @param rewardsPerSecond
 * @param rewardTokenPrice reward price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getSecondaryRewardApr = (
  rewardsPerSecond: BigNumber,
  rewardTokenPrice: BigNumber,
  poolLiquidityUsd: BigNumber,
): { rewardsApr: number } => {
  const yearlyRewardsAllocation = rewardsPerSecond.times(SECONDS_PER_YEAR)
  const tokenRewardsApr = yearlyRewardsAllocation.times(rewardTokenPrice).div(poolLiquidityUsd).times(100)
  let rewardsApr = null
  if (!tokenRewardsApr.isNaN() && tokenRewardsApr.isFinite()) {
    rewardsApr = tokenRewardsApr.toNumber()
  }
  return { rewardsApr }
}

export default null
