import tokens from './tokens'
import { Ino } from './types'

const inos: Ino[] = [
  {
    id: 'nfm',
    address: '0xbe928098e7a0a4bfceb2e8007f37c5848d2a217e',
    isActive: true,
    name: 'Non-Fungible Moonbirds',
    description:
      'Non-Fungible Moonbirds is a unique collection of 8,888 algorithmically generated digital Moonbirds created by Moonbird Finance. They will adhere to a strict rarity and occurrence breakdown, ensuring a perfect distribution across every different trait. The launch will be through a Dutch Auction format and an NFM index token: NFMi. Please read the article and information below for all the details.',
    saleAmount: '533,280 NFMi',
    currency: tokens.bnb,
    token: tokens.nfmi,
    releaseTime: 1646137600,
    startTime: 1646337600,
    tokensToSell: 533280000000000000000000,
    campaignId: '511111000',
    articleUrl:
      'https://liquidcollectibles.medium.com/non-fungible-moonbirds-launch-on-liquid-collectibles-8b406ab183cb',
    startPrice: 0.003,
    minimumPrice: 0.0001,
    version: 2,
    isDutch: true,
  },
  {
    id: 'nfb',
    address: '0x4caa4fa97d03216851d6fb2a8acd42c227ff1f43',
    isActive: false,
    name: 'Non-Fungible Bananas',
    description:
      'Non-Fungible Bananas are a unique collection of 10,000 algorithmically generated digital bananas created by ApeSwap. They will adhere to a strict rarity and occurrence breakdown, ensuring a perfect distribution across every different trait. The launch will be through a Dutch Auction format and an NFB index token: NFBi. Please read the article and information below for all the details.',
    saleAmount: '650,000 NFBi',
    currency: tokens.bnb,
    token: tokens.nfbi,
    releaseTime: 1637647259,
    startTime: 1637964000,
    tokensToSell: 650000000000000000000000,
    campaignId: '511110000',
    articleUrl: 'https://ape-swap.medium.com/non-fungible-bananas-the-launch-9d177ed9d0a7',
    startPrice: 0.0085,
    minimumPrice: 0.0005,
    version: 2,
    isDutch: true,
  },
]

export default inos
