import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  getAddress,
  getLicoAddress,
  getMasterChefAddress,
  getLicoVaultAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getFarmAuctionAddress,
  getLiquidMonstersNftAddress,
  getNftZapAddress,
  getRouterAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import baseNftAbi from 'config/abi/baseNft.json'
import nftVault from 'config/abi/nftVault.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import licoABI from 'config/abi/lico.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import dutchAuctionAbi from 'config/abi/dutchAuction.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import licoVaultAbi from 'config/abi/licoVault.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import liquidMonsterAbi from 'config/abi/liquidMonster.json'
import nftZapAbi from 'config/abi/nftZap.json'
import routerAbi from 'config/abi/router.json'
import { ChainLinkOracleContract, FarmAuctionContract } from './types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}
export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getBaseNftContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(baseNftAbi, address, signer)
}
export const getNftVaultContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftVault, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getDutchAuctionContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(dutchAuctionAbi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getLicoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(licoABI, getLicoAddress(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getLicoVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(licoVaultAbi, getLicoVaultAddress(), signer)
}
export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getFarmAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as FarmAuctionContract
}
export const getLiquidMonstersContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(liquidMonsterAbi, getLiquidMonstersNftAddress(), signer)
}
export const getNftZapContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftZapAbi, getNftZapAddress(), signer)
}
export const getRouterContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(routerAbi, getRouterAddress(), signer)
}
