import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Text, Link, Button, useMatchBreakpoints } from '@liquidcollectibles/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchLicoVault, useLicoVault, useLicoPoolApr } from 'state/pools/hooks'
import { usePollFarmsData } from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import LicoFooter from 'views/Home/components/LicoFooter'
import PageHeader from 'components/PoolPageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Loading from 'components/Loading'
import { getAddress } from 'utils/addressHelpers'
import tokens from 'config/constants/tokens'
import PoolCard from './components/PoolCard'
import LicoVaultCard from './components/LicoVaultCard'
import PoolTabButtons from './components/PoolTabButtons'
import BountyCard from './components/BountyCard'
import PoolsTable from './components/PoolsTable/PoolsTable'
import ToggleView, { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getLicoVaultEarnings } from './helpers'
import FooterLogoSvg from './components/FooterLogoSvg'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  position: relative;
  flex-wrap: wrap;

  justify-content: center;
  margin: 16px 0px 32px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    width: 100%;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-items: flex-start;
  width: 100%;
`

const ToggleWrapper = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  margin: 4px 4px 4px 0px;

  ${Text} {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0px 4px 0px 4px;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`

const BottomLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 48px auto 0px auto;
`

const NUMBER_OF_POOLS_VISIBLE = 14

const Pools: React.FC = () => {
  const { pathname, hash, key } = useLocation()
  const isXs = useMatchBreakpoints()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  poolsWithoutAutoVault[0].apr = useLicoPoolApr()
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'lico_pool_staked' })
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.CARD, { localStorageKey: 'lico_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { licoAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalLicoInVault,
  } = useLicoVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const LicoPool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const licoAutoVault = { ...LicoPool, isAutoVault: true }
    return [licoAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyOpenPools = useMemo(
    () =>
      openPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [openPools, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchLicoVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const showFinishedPools = pathname.includes('history')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getLicoVaultEarnings(
                  account,
                  licoAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalLicoInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools
  if (showFinishedPools) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
  } else {
    chosenPools = stakedOnly ? stakedOnlyOpenPools : openPools
  }

  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)

        if (element) {
          const yOffset = -80
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }, 0)
    }
  }, [pathname, hash, key])

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) => (
        <div id={!pool.isAutoVault ? pool?.earningToken.symbol : 'LICO-auto'}>
          {pool.isAutoVault ? (
            <LicoVaultCard key="auto-lico" pool={pool} showStakedOnly={stakedOnly} />
          ) : (
            <PoolCard key={pool.sousId} pool={pool} account={account} />
          )}
        </div>
      ))}
    </CardLayout>
  )

  const tableLayout = <PoolsTable pools={chosenPools} account={account} userDataLoaded={userDataLoaded} />

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xl" color="text">
              {t('Staking Pools')}
            </Heading>
            <Text color="textSubtle" mb="24px">
              {t('High APR, low risk.')}
            </Text>
            <HeaderButtonsContainer>
              {!account && <ConnectWalletButton variant="licoprimary" mr="8px" />}

              <Link
                external
                href={`https://dex.apeswap.finance/swap?outputCurrency=${getAddress(tokens.lico.address)}`}
              >
                <Button mr="8px" variant="licoprimary">
                  {t('Buy LICO')}
                </Button>
              </Link>
              <Link mt={isXs ? '8px' : '0px'} external href="https://docs.liquidcollectibles.io/tutorials/staking">
                <Button variant="secondary">{t('Learn more')}</Button>
              </Link>
            </HeaderButtonsContainer>
          </Flex>
          <Flex flex="1" height="fit-content" justifyContent="center" alignItems="center" mt={['24px', null, '0']}>
            <BountyCard />
          </Flex>
        </Flex>
      </PageHeader>

      <Page>
        <ControlContainer>
          <ViewControls>
            <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
          </ViewControls>
          <FilterContainer>
            <SearchInput onChange={handleChangeSearchQuery} placeholder="Search Pools" />

            <ToggleWrapper>
              <Button
                variant={stakedOnly ? 'quaternarypressed' : 'quaternary'}
                scale="mdfix"
                defaultChecked={stakedOnly}
                onClick={() => setStakedOnly(!stakedOnly)}
              >
                {' '}
                Staked{' '}
              </Button>
            </ToggleWrapper>

            <PoolTabButtons
              hasStakeInFinishedPools={hasStakeInFinishedPools}
              stakedOnly={stakedOnly}
              setStakedOnly={setStakedOnly}
            />

            <Select
              options={[
                {
                  label: t('Hot'),
                  value: 'hot',
                },
                {
                  label: t('APR'),
                  value: 'apr',
                },
                {
                  label: t('Earned'),
                  value: 'earned',
                },
                {
                  label: t('Total staked'),
                  value: 'totalStaked',
                },
              ]}
              onChange={handleSortOptionChange}
            />
          </FilterContainer>
        </ControlContainer>

        {showFinishedPools && (
          <Text textAlign="center" fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {viewMode === ViewMode.CARD ? cardLayout : tableLayout}
        <div ref={loadMoreRef} />

        <BottomLogo>
          <FooterLogoSvg />
        </BottomLogo>
      </Page>
      <LicoFooter />
    </>
  )
}

export default Pools
