import React from 'react'

const TelegramSvg = (props) => (
  <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" height="44px" {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={192.51}
        x2={44}
        y2={192.51}
        gradientTransform="matrix(1 0 0 -1 0 214.51)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d22a82" />
        <stop offset={1} stopColor="#ec5b39" />
      </linearGradient>
    </defs>
    <circle
      cx={22}
      cy={22}
      r={21.5}
      style={{
        fill: 'none',
        stroke: 'url(#a)',
      }}
    />
    <path
      d="m31.11 12.34-20.69 8C9 20.88 9 21.67 10.17 22l5.31 1.66 12.28-7.76c.58-.35 1.12-.16.68.23l-10 9-.36 5.48a1.39 1.39 0 0 0 1.07-.54l2.58-2.51 5.37 4c1 .55 1.7.27 1.94-.91L32.61 14c.39-1.44-.56-2.09-1.5-1.66Z"
      style={{
        fill: '#fff',
        fillOpacity: 0.87,
      }}
    />
  </svg>
)

export default TelegramSvg
