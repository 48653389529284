import React from 'react'
import styled from 'styled-components'
import { Box } from '@liquidcollectibles/uikit'
import Container from '../Layout/Container'

const Outer = styled(Box)`
  height: auto;
  background: linear-gradient(119.63deg, #de3a66 -97.37%, #050505 56.23%);
`

const Inner = styled(Container)`
  max-width: 1152px;
  margin: 0px auto;
  padding: 24px;
`

const PoolPageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PoolPageHeader
