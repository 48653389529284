/* eslint-disable camelcase */
import { NftAttributes } from 'config/constants/types'
import { useEffect, useState } from 'react'
import useRefresh from './useRefresh'

const apiUrl = process.env.REACT_APP_API_URL

export interface ApiCollectionResponse {
  _id: string
  contractAddress: string
  __v: number
  chainId: number
  updatedAt: Date
  description: string
  externalUrl: string
  name: string
  attributes: string[]
}

export interface ApiCollectibleResponse {
  contractAddress: string
  tokenId: number
  chainId: number
  external_url: string
  image: string
  name: string
  attributes: NftAttributes[]
}

export const FetchAllCollectionsApi = (): ApiCollectionResponse[] => {
  const url = `${apiUrl}/collections`
  const [data, setData] = useState<ApiCollectionResponse[]>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url)
        const responseData: ApiCollectionResponse[] = await response.json()
        setData(responseData)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }

    fetchData()
  }, [setData, url])
  return data
}

export const FetchCollectionApi = (address: string): ApiCollectionResponse => {
  const url = `${apiUrl}/collections/${address}`
  const [data, setData] = useState<ApiCollectionResponse>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url)
        const responseData: ApiCollectionResponse = await response.json()

        setData(responseData)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }

    fetchData()
  }, [setData, url])
  return data
}

export async function fetchCollectibles(address, skip = 0, limit = 500) {
  const url = `${apiUrl}/collections/collectibles/${address}?skip=${skip}&limit=${limit}`
  const response = await fetch(url)
  const responseData: ApiCollectibleResponse[] = await response.json()
  return responseData
}

export async function fetchAllCollectibles(address) {
  let responseData = []
  let skip = 0
  const limit = 5000
  let fetched = []
  while (fetched.length === limit || responseData.length === 0) {
    // eslint-disable-next-line no-await-in-loop
    fetched = await fetchCollectibles(address, skip, limit)
    responseData = [...responseData, ...fetched]
    skip += limit
  }
  return responseData
}

export async function getAllNftsFromWallet(address) {
  const url = `${apiUrl}/user/nfts/${address}`
  let responseData: ApiCollectibleResponse[]
  try {
    const response = await fetch(url)
    responseData = await response.json()
  } catch (error) {
    console.error('Unable to fetch data:', error)
  }
  return responseData
}

export const useGetUserNftsFromAPI = (
  address: string,
): { nftsInWallet: ApiCollectibleResponse[]; loading: boolean } => {
  const [nftsInWallet, setData] = useState<ApiCollectibleResponse[]>(null)
  const { fastRefresh } = useRefresh()
  const [loading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData: ApiCollectibleResponse[] = await getAllNftsFromWallet(address)
        setData(responseData)
        setIsLoading(false)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }
    if (address) fetchData()
  }, [address, fastRefresh])

  return { nftsInWallet, loading }
}

export const useGetCollectionFromAPi = (address: string): ApiCollectibleResponse[] => {
  const [data, setData] = useState<ApiCollectibleResponse[]>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData: ApiCollectibleResponse[] = await fetchAllCollectibles(address)

        setData(responseData)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }
    fetchData()
  }, [address])

  return data
}

export const useGetCollectible = (address: string, tokenId: number): ApiCollectibleResponse => {
  const [data, setData] = useState<ApiCollectibleResponse>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${apiUrl}/collections/collectibles/${address}/${tokenId}`
        const response = await fetch(url)
        const responseData: ApiCollectibleResponse = await response.json()

        setData(responseData)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }

    fetchData()
  }, [address, tokenId])

  return data
}
