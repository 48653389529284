export default {
  masterChef: {
    97: '0xb3369AbBbB93c26cf8CCB942757d9B41E5be6C18',
    56: '0x25eF2439ee92552B6f58463394E66D289F9e7b7C',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  liquidMonstersFactory: {
    56: '0x729468D17Df9EcEBE068A87CA73B9E4e241f2a9f',
    97: '0x729468D17Df9EcEBE068A87CA73B9E4e241f2a9f',
  },
  liquidMonsterNft: {
    56: '0x0d464bdde2301c30871bb4c29bb7dd935f5a985c',
    97: '0x512d8CcB7c09675Bf5732840c040E009A5660Fe6',
  },
  licoVault: {
    56: '0x6c3B1Ff7481fE0B106D046CfE60c5751caA30c7d',
    97: '0xF74e498C3231Ed384F1A946492A1809008c5a5a3',
  },
  limoVault: {
    56: '0x3aBAa5B35e5909106098Ab3aCce352C3c7007a98',
    97: '0x7B9Fd8c305EFa0e97445372328377D99165Cd3E0',
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  nftZap: {
    56: '0x0b3db32441eC49ad32d551eb1046074f9A75B9ee',
    97: '0x9D3Cfb05218cE053244bC429C558a17Ed2CF41c7',
  },
  apeRouter: {
    56: '0xcf0febd3f17cef5b47b0cd257acf6025c5bff3b7',
    97: '0x3380ae82e39e42ca34ebed69af67faa0683bb5c1',
  },
}
