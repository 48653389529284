import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getLicoAddress = () => {
  return getAddress(tokens.lico.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getLicoVaultAddress = () => {
  return getAddress(addresses.licoVault)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getLiquidMonstersNftAddress = () => {
  return getAddress(addresses.liquidMonsterNft)
}
export const getNftZapAddress = () => {
  return getAddress(addresses.nftZap)
}
export const getRouterAddress = () => {
  return getAddress(addresses.apeRouter)
}
